/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Product Sans Regular';
  src: url('assets/fonts/Product-Sans/Product-Sans-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Product Sans Italic';
  src: url('assets/fonts/Product-Sans/Product-Sans-Italic.ttf')
    format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Product Sans Bold';
  src: url('assets/fonts/Product-Sans/Product-Sans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Product Sans Bold Italic';
  src: url('assets/fonts/Product-Sans/Product-Sans-Bold-Italic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  src: url('assets/fonts/Montserrat/Montserrat-ExtraBold.otf')
    format('opentype');
  font-weight: bold;
}

* {
  font-family: 'Product Sans Regular', sans-serif;
}

.header-title {
  font-family: 'Montserrat Extra Bold', serif;
}

html,
body {
  height: 100%;
}

.content {
  min-height: calc(100vh - (48px + 71px));
  display: block;
}

.hot-container {
  width: 100%;
  height: calc(100vh - (48px + 71px));
  overflow: hidden;
}
.poste-hta-hot-container {
  width: 100%;
  height: calc(100vh - (48px + 130px));
  overflow: hidden;
}

.poste-hta-hot-container {
  width: 100%;
  height: calc(100vh - (48px + 120px));
  overflow: hidden;
}

.btn-akilee {
  color: #fff;
  background: rgb(54, 124, 185);
  background: -moz-linear-gradient(
    146deg,
    rgba(54, 124, 185, 1) 0%,
    rgba(70, 65, 147, 1) 100%
  );
  background: -webkit-linear-gradient(
    146deg,
    rgba(54, 124, 185, 1) 0%,
    rgba(70, 65, 147, 1) 100%
  );
  background: linear-gradient(
    146deg,
    rgba(54, 124, 185, 1) 0%,
    rgba(70, 65, 147, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#367cb9",endColorstr="#464193",GradientType=1);
}

.btn-akilee:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-akilee:focus,
.btn-akilee.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-akilee.disabled,
.btn-akilee:disabled {
  color: #fff;
  background-color: #2d75c5;
  border-color: #2d75c5;
}

.btn-akilee:not(:disabled):not(.disabled):active,
.btn-akilee:not(:disabled):not(.disabled).active,
.show > .btn-akilee.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-akilee:not(:disabled):not(.disabled):active:focus,
.btn-akilee:not(:disabled):not(.disabled).active:focus,
.show > .btn-akilee.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-outline-akilee {
  color: rgb(54, 124, 185);
  border-color: rgb(54, 124, 185);
}

.btn-outline-akilee:not(:disabled):not(.disabled):hover,
.btn-outline-akilee:not(:disabled):not(.disabled).active {
  color: #fff;
  background: rgb(54, 124, 185);
  background: -moz-linear-gradient(
    146deg,
    rgba(54, 124, 185, 1) 0%,
    rgba(70, 65, 147, 1) 100%
  );
  background: -webkit-linear-gradient(
    146deg,
    rgba(54, 124, 185, 1) 0%,
    rgba(70, 65, 147, 1) 100%
  );
  background: linear-gradient(
    146deg,
    rgba(54, 124, 185, 1) 0%,
    rgba(70, 65, 147, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#367cb9",endColorstr="#464193",GradientType=1);
  border-color: rgb(54, 124, 185);
}

.btn-outline-akilee:focus,
.btn-outline-akilee.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-akilee.disabled,
.btn-outline-akilee:disabled {
  color: #2d75c5;
  background-color: transparent;
}

.btn-sm-hot {
  padding: 0.05rem 0.25rem;
}

.green-cell {
  background-color: #3d8056 !important;
}

.red-cell {
  background-color: #803052 !important;
}

:root {
  --bo-background-gradient: linear-gradient(146deg, #367cb9 0%, #464193 100%);
  --bo-row-odd-background: #e9f6fe;
}
.bo-text-align-center {
  text-align: center;
}
.bo-background-gradient {
  color: white;
}
.bo-background-gradient {
  background: var(--bo-background-gradient);
}
.bo-row-odd {
  background-color: var(--bo-row-odd-background);
}
.bo-mr-2 {
  margin-right: 2px;
}
.bo-p-20 {
  padding: 20px;
}
.bo-margin-top-10 {
  margin-top: 10px;
}
.bo-margin-top-30 {
  margin-top: 30px;
}
.bo-pointer-cursor {
  cursor: pointer;
}
// flexbox
.bo-flex {
  display: flex;
}
// ag grid
.bo-ag-grid-container {
  height: calc(100vh - 150px);
  .ag-root {
    border: none;
  }
  .bo-ag-grid {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
    box-shadow: 0 0 8px #9b9b9b;
    // box-shadow: 0 1px 5px 0 rgba(84,84,84,0.2), 0 2px 2px 0 rgba(84,84,84,0.2), 0 3px 1px -2px rgba(84,84,84,0.2);
    .ag-header {
      color: #fff;
      background: var(--bo-background-gradient);
      font-weight: 400;
      .ag-icon-menu,
      .ag-icon-asc,
      .ag-icon-desc {
        color: #fff;
      }
      .ag-floating-filter-input {
        border-radius: 4px;
        border: none;
      }
    }
    .ag-row {
      border: none;
    }
    .ag-row-odd {
      background-color: var(--bo-row-odd-background);
    }
    .ag-row-hover {
      background-color: #e6e9ec !important;
      color: black !important;
      cursor: pointer !important;
    }
  }
}

.bo-center {
  margin: auto;
  height: 500px;
  padding: 10px;
  text-align: center;
}

//floating menu
.floating-menu {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  margin: 15px 0px 0px 0px;
  margin-left: 35%;
  .menu-elements {
    margin: 0 auto;
    width: max-content;
    padding: 20px 25px 30px 25px;
    background: black;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    button {
      width: 82px !important;
    }
  }
}
// floating menu - end

// bootstrap modals
.modal-content {
  border: none;
  //font-size: 13px;
  .modal-header {
    background: var(--bo-background-gradient);
    color: white;
    .close,
    .breadcrumb-item::before,
    a {
      color: white;
    }
  }
}
// bootstrap modals - end

// bootstrap card
.card {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.13);
}
// bootstrap card - end

// Scrollbar https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp
::-webkit-scrollbar {
  width: 5px;
}
// handle
::-webkit-scrollbar-thumb {
  background: var(--bo-background-gradient);
}
// Scrollbar - end

// anomalies
.anomalies-report-container {
  padding-bottom: 20px;
  h4 {
    opacity: 0.8;
  }
  h6 {
    opacity: 0.6;
  }
  .nombre-anomalies-chart {
    width: 100%;
    height: 250px;
    display: block;
  }
  .duree-moyenne-charts {
    height: 300px;
    display: block;
  }
  .repartition-zone-chart {
    height: 400px;
    display: block;
  }
  .card-header {
    padding: 0.6rem 0.5rem;
    span {
      opacity: 0.65;
    }
  }
}
.anomalies-taux-reception-container {
  .bo-ag-grid-container {
    height: calc(100vh - 140px);
  }
}
.anomalies-taux-reception-ag-grid {
  .ag-floating-filter-button {
    display: none;
  }
}
.anomalies-btn-group-toggle {
  .btn-outline-akilee:focus,
  .btn-outline-akilee.focus {
    box-shadow: none;
  }
}
.anomalies-container {
  padding-left: 25px;
  .bo-ag-grid-container {
    height: calc(100vh - 155px);
    margin-top: 0.5em;
  }
}
// anomalies - end

// custom legend
.custom-chart-legend {
  .legend-color {
    height: 0.75em;
    width: 0.75em;
    border-radius: 50%;
    display: inline-block;
  }
  margin: 0.8em 0 1.4em 0;
}
// custom legend - end

// Scota events
.events-container {
  padding-left: 20px;
  .bo-ag-grid-container {
    height: calc(100vh - 105px);
  }
  .events-ag-grid {
    .ag-floating-filter-button {
      display: none;
    }
  }
}
.badge-grandeur {
  min-width: 40px;
}
.event-grandeurs {
  padding: 5px;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
}
// Scota events - end
